import React from 'react';
import {render} from 'react-dom';
import {Routes, Route, HashRouter} from 'react-router-dom';
import {AppProvider} from '@shopify/polaris';
// import {Home} from './pages/Home';
import {Challenge} from './pages/Challenge';
import {Messaging} from './pages/Messaging';
import {Login} from './pages/Login';
import {Signup} from './pages/Signup';

// Import the functions you need from the SDKs you need
import {initializeApp} from 'firebase/app';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyAM-29ZrEYHt3b3SDOxjndcT36N1ckirH4',
  authDomain: 'comp3109-249d6.firebaseapp.com',
  databaseURL:
    'https://comp3109-249d6-default-rtdb.firebaseio.com',
  projectId: 'comp3109-249d6',
  storageBucket: 'comp3109-249d6.appspot.com',
  messagingSenderId: '580671497364',
  appId: '1:580671497364:web:aa90c2b7066c75c5d50081',
  measurementId: 'G-2VGBMMFGYR',
};

// Initialize Firebase
initializeApp(firebaseConfig);

const rootElement = document.getElementById('root');
render(
  <AppProvider>
    <HashRouter>
      <Routes>
        <Route exact path="/" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route
          path="/challenge/:id"
          element={<Challenge />}
        />
        <Route path="/messaging" element={<Messaging />} />
      </Routes>
    </HashRouter>
  </AppProvider>,
  rootElement,
);
