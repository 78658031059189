import React, { useState } from 'react';
import {
  AppProvider,
  Page,
  DisplayText,
  TextField,
  Stack,
  Button,
  Form,
} from '@shopify/polaris';
import { useParams } from 'react-router-dom';
import { CopyBlock, dracula } from 'react-code-blocks';
import axios from 'axios';

import { Attempts } from './components/Attempts';

import './Challenge.css';

function Challenge() {
  const [textFieldValue, setTextFieldValue] = useState('');
  const [errorMessage, setErrorMessage] = useState({
    show: false,
    content: '',
  });
  const [pass, setPass] = useState(false);
  const [attemptList, setAttempList] = useState([]);

  const { id } = useParams();

  const codeSnippet = `# THIS FUNCTION HAS TAKEN YOUR STUDENT ID AS INPUT
# THE SHIFT VALUE IS A RANDOM CONSTANT
def hash(input):
    return input % 7 + SHIFT // input`;

  const handleTextFieldChange = (value) => {
    setTextFieldValue(value);
    setErrorMessage({
      show: false,
      content: '',
    });
  };

  const addAttempt = (attempt) => {
    const newList = [...attemptList, attempt];
    if (newList.length > 50) {
      newList.shift();
    }

    console.log(newList);

    setAttempList(newList);
  };

  const handleVerify = async () => {
    if (textFieldValue === '') {
      setErrorMessage({
        show: true,
        content: 'No empty guesses!',
      });

      return;
    } else {
      setErrorMessage({
        show: false,
      });
    }

    if (textFieldValue === id) {
      setErrorMessage({
        show: true,
        content: 'Invalid guess',
      });

      return;
    }

    const result = await axios.get(
      `https://us-central1-comp3109-249d6.cloudfunctions.net/verify`,
      {
        params: {
          id,
          attempt: Number(textFieldValue),
        },
      },
    );

    if (
      !result ||
      !result.data ||
      result.data.pass === undefined
    ) {
      setErrorMessage({
        show: true,
        content: 'Server error.',
      });
      return;
    }

    const pass = result.data.pass;

    if (pass) {
      setPass(true);
    }

    addAttempt(textFieldValue);
  };

  return (
    <AppProvider>
      <Page title="COMP 3109 team whatever">
        <Stack spacing="loose" vertical>
          <DisplayText size="small">
            What is your challenge?
          </DisplayText>
          <p>
            Explain what their challenge is, blah blah blah
          </p>
          <div className="Demo">
            <CopyBlock
              language="python"
              text={codeSnippet}
              showLineNumbers
              theme={dracula}
            />
          </div>
          <Form onSubmit={handleVerify}>
            <Stack spacing="loose" vertical>
              <TextField
                label="Enter hash output"
                value={textFieldValue}
                onChange={handleTextFieldChange}
                type="number"
                monospaced
              />
              {errorMessage.show ? (
                <p>{errorMessage.content}</p>
              ) : (
                <p></p>
              )}
              <Button onClick={handleVerify}>Check</Button>
            </Stack>
          </Form>
          <DisplayText size="small">
            {`Challenge status: ${
              pass ? 'Solved ✅' : 'Unsolved ❌'
            }`}
          </DisplayText>
          <Attempts attempts={attemptList} />
        </Stack>
      </Page>
    </AppProvider>
  );
}

export default Challenge;
