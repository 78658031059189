import React, {
  useState,
  useEffect,
  useCallback,
} from 'react';
import {
  Page,
  TextField,
  Button,
  Form,
  Stack,
  Card,
  EmptyState,
} from '@shopify/polaris';
import {
  getFirestore,
  collection,
  getDocs,
  addDoc,
} from 'firebase/firestore';
import tea from 'tea';
import {getAuth, signOut} from 'firebase/auth';
import {useNavigate} from 'react-router-dom';
import './Messaging.css';

import {initCurve, getPoint} from '../../ecc/curve';

export default function Messaging() {
  // const [loading, setLoading] = useState(true);
  const [messageValue, setMessageValue] = useState('');
  const [keyValue, setKeyValue] = useState('');
  const [messages, setMessages] = useState([]);
  // const [keyLoading, setKeyLoading] = useState(false);
  const navigate = useNavigate();

  const [secretValue, setSecretValue] = useState('');
  const [baseNum, setBaseNum] = useState('');
  const [genResult, setGenResult] = useState('');

  const db = getFirestore();

  const auth = getAuth();

  const pollMessages = useCallback(async () => {
    // setLoading(true);
    const messagesRef = await getDocs(
      collection(db, 'messages'),
    );

    const nextMessages = [];
    messagesRef.forEach((messageRef) => {
      nextMessages.push(messageRef.data());
    });

    nextMessages.sort(
      (a, b) =>
        new Date(b.timestamp) - new Date(a.timestamp),
    );

    const nextMessagesSorted = nextMessages.map(
      (message) => message.message,
    );

    setMessages(nextMessagesSorted);
    // setLoading(false);
  }, [db]);

  useEffect(() => {
    pollMessages();
  }, [pollMessages]);

  useEffect(() => {
    initCurve();
  }, []);

  // const getRandomKey = async () => {
  //   setKeyLoading(true);
  //   const result = await axios.get(
  //     `https://us-central1-comp3109-249d6.cloudfunctions.net/rng`,
  //   );

  //   if (
  //     !result ||
  //     !result.data ||
  //     result.data.password === undefined
  //   ) {
  //     setKeyLoading(false);
  //     return;
  //   }

  //   const password = result.data.password;

  //   setKeyValue(password.toString());
  //   setKeyLoading(false);
  // };

  // document.body.style.overflow = 'hidden';

  // if (loading) {
  //   return (
  //     <Frame>
  //       <Loading />
  //     </Frame>
  //   );
  // }

  const handleLoginClick = () => {
    navigate('/');
  };

  const handleSignupClick = () => {
    navigate('/signup');
  };

  if (!auth?.currentUser?.uid) {
    return (
      <Card sectioned>
        <EmptyState
          heading="Oops, no user account found!"
          action={{
            content: 'Login',
            onClick: handleLoginClick,
          }}
          secondaryAction={{
            content: 'Sign up',
            onClick: handleSignupClick,
          }}
          image="https://cdn.shopify.com/s/files/1/0262/4071/2726/files/emptystate-files.png"
        >
          <p>Please create an account.</p>
        </EmptyState>
      </Card>
    );
  }

  console.log(auth.currentUser.uid);

  const getMessages = () => {
    return messages.map((message) => {
      const messageToRender = tea.decrypt(
        message,
        keyValue,
      );
      return (
        <div className={'Message'}>
          <p>Message: {messageToRender}</p>
        </div>
      );
    });
  };

  const handleSendMessage = async () => {
    const messageToSend = tea.encrypt(
      messageValue,
      keyValue,
    );
    try {
      const docRef = await addDoc(
        collection(db, 'messages'),
        {
          message: messageToSend,
          timestamp: Date.now(),
        },
      );

      console.log('Document written with ID: ', docRef.id);
      pollMessages();
    } catch (e) {
      console.error('Error adding document: ', e);
    }
  };

  const handleMessageChange = (value) => {
    setMessageValue(value);
  };

  const handleGenPoint = async () => {
    let n = Math.floor(Number(secretValue));
    let newPnt;

    if (n <= 0) {
      alert('Secret must be a positive integer.');
      return;
    }

    if (baseNum !== '' && baseNum.slice(0, 2) !== '0x') {
      alert('Base must be a hexadecimal value.');
      return;
    }

    if (baseNum === '') newPnt = getPoint(n, null);
    else {
      let curr = baseNum.slice(2);
      newPnt = getPoint(n, curr);
    }

    setGenResult('0x' + newPnt);
  };

  const handleSecretChange = (value) => {
    let n = Math.floor(Number(value));
    if (n > 0) setSecretValue(n);
    else setSecretValue(value);
  };

  const handelBaseChange = (value) => {
    setBaseNum(value);
  };

  const handleKeyChange = (value) => {
    setKeyValue(value);
  };

  const handleLogoutClick = () => {
    signOut(auth)
      .then(() => {
        navigate('/');
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <Page breadcrumbs={[{url: '/'}]}>
      <div className={'PageContainer'}>
        <div className={'MessagingContainer'}>
          <div className={'ChatContainer'}>
            {getMessages()}
          </div>
          <div className={'OptionsContainer'}>
            <div className={'TextFieldContainer'}>
              <Form onSubmit={handleSendMessage}>
                <div className={'MessageField'}>
                  <TextField
                    placeholder="Message"
                    value={messageValue}
                    onChange={handleMessageChange}
                  />
                </div>
                <div className={'MessageField'}>
                  <Stack>
                    <TextField
                      placeholder="Key"
                      value={keyValue}
                      onChange={handleKeyChange}
                    />
                    {/* <Button
                      onClick={getRandomKey}
                      loading={keyLoading}
                    >
                      Get random key
                    </Button> */}
                  </Stack>
                </div>
                <Button onClick={handleSendMessage}>
                  Send message
                </Button>
              </Form>
              <Form onSubmit={handleGenPoint}>
                <div className={'MessageField'}>
                  <TextField
                    placeholder="Secret n"
                    value={secretValue.toString()}
                    onChange={handleSecretChange}
                  />
                </div>
                <div className={'MessageField'}>
                  <TextField
                    placeholder="Base Point"
                    value={baseNum}
                    onChange={handelBaseChange}
                  />
                </div>
                <div className={'MessageField'}>
                  <TextField
                    placeholder="Generated #"
                    value={genResult}
                    disabled={true}
                  />
                </div>
                <Button onClick={handleGenPoint}>
                  Generate Point
                </Button>
              </Form>
              <Button onClick={handleLogoutClick}>
                Logout
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Page>
  );
}
