class Point {
  constructor(x, y) {
    this.x = x;
    this.y =y;
  }
}

class Curve {
  constructor() {
    this.a = 2;
    this.b = 3;
    this.max = Math.pow(2, 16) - 15;

    this.start = null;
    this.curr = null;
  }

  setStart(pnt) {
    this.start = pnt;
    this.curr = null;
  }

  setCurr(pnt) {
    this.curr = pnt;
  }

  at(x) {
    let val = (Math.pow(x, 3) + (this.a * x) + this.b) % this.max;

    return sqrtModP(val, this.max);
  }

  add() {
    let p1 = this.start;
    let p2 = this.curr ? this.curr : p1;

    // console.log(p1, p2)

    let denom1 = (2 * p1.y) % this.max;
    let denom2 = (this.max + p2.x - p1.x) % this.max;

    let d = denom2 === 0 ? 
      ((3 * (Math.pow(p1.x, 2) % this.max) + this.a) * invrsModP(denom1, this.max)) % this.max:
      ((this.max + p2.y - p1.y) * invrsModP(denom2, this.max)) % this.max;

    let x = (this.max + Math.pow(d, 2) - p1.x - p2.x) % this.max;
    let y = (this.max + (d * ((this.max + p1.x - x) % this.max)) - p1.y) % this.max;

    this.curr = new Point(x, y);

    return this.curr;
  }

  mult(n) {
    for(let i = 0; i < n; i++){
      this.add();
      // console.log(this.add());
    }

    return this.curr;
  }
}

const sqrtModP = (n, p) => {
  n = n % p;

  for(let i = 2; i < p; i++){
    if(Math.pow(i, 2) % p === n){
      return i;
    }
  }

  return null;
}

const invrsModP = (n, p) => {
  n = n % p;

  for(let x = 1; x < p; x++){
    if ((n * x) % p === 1){
      return x;
    }
  }
}

let curve;

export const initCurve = () => {
  curve = new Curve();
  curve.setStart(new Point(278, 130));
}

export const getPoint = (n, hexCurr) => {
  if(hexCurr){
    let x = parseInt(hexCurr.slice(0,4), 16);
    let y = parseInt(hexCurr.slice(4), 16);

    // console.log(x, y);

    curve.setCurr(new Point(x, y));
  } else{
    curve.setCurr(null);
  }

  let newCurr = curve.mult(n);
  
  let hexPnt = "".concat(("0000" + newCurr.x.toString(16)).slice(-4), ("0000" + newCurr.y.toString(16)).slice(-4));

  return hexPnt;
}

// const main = () => {
//   let curve1 = new Curve();
//   let curve2 = new Curve();
//   let curve3 = new Curve();

//   let a = 1234;
//   let b = 243;
//   let c = 512;

//   let pnt = new Point(278, 130);

//   curve1.setStart(pnt);
//   curve1.mult(a);
//   curve1.mult(b);
//   console.log(curve1.mult(c));
  
//   curve2.setStart(pnt);
//   curve2.mult(b);
//   curve2.mult(c);
//   console.log(curve2.mult(a));

//   curve3.setStart(pnt);
//   curve3.mult(c);
//   curve3.mult(a);
//   console.log(curve3.mult(b));
// }

// main();