import React, {useState} from 'react';
import {
  getAuth,
  createUserWithEmailAndPassword,
} from 'firebase/auth';
import {
  AppProvider,
  TextField,
  Page,
  Stack,
  Button,
  Form,
} from '@shopify/polaris';
import {useNavigate} from 'react-router-dom';

export default function Signup() {
  const [emailValue, setEmailValue] = useState('');
  //   const [confirmEmailValue, setConfirmEmailValue] =
  // useState('');
  const [passwordValue, setPasswordValue] = useState('');
  const navigate = useNavigate();
  const auth = getAuth();

  const handleEmailChange = (value) => {
    setEmailValue(value);
  };

  //   const handleConfirmEmailChange = (value) => {
  //     setConfirmEmailValue(value);
  //   };

  const handlePasswordChange = (value) => {
    setPasswordValue(value);
  };

  const handleLoginClick = () => {
    navigate('/');
  };

  const handleSignupClick = () => {
    createUserWithEmailAndPassword(
      auth,
      emailValue,
      passwordValue,
    )
      .then((userCredential) => {
        navigate('/messaging');
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <AppProvider>
      <Page>
        <Stack spacing="extraLoose">
          <Form onSubmit={handleSignupClick}>
            <TextField
              value={emailValue}
              onChange={handleEmailChange}
              placeholder="Email"
              type="email"
            />
            {/* <TextField
              value={emailValue}
              onChange={handleConfirmEmailChange}
              placeholder="Confirm email"
              type="email"
            /> */}
            <TextField
              value={passwordValue}
              onChange={handlePasswordChange}
              placeholder="Password"
              type="password"
            />
            <Button onClick={handleSignupClick}>
              Sign up
            </Button>
            <Button onClick={handleLoginClick}>
              Log in
            </Button>
          </Form>
        </Stack>
      </Page>
    </AppProvider>
  );
}
