import React from 'react';

export default function Attempts({ attempts }) {
  const attemptsMarkup = () => {
    return attempts.map((attempt) => <p>{attempt}</p>);
  };

  return (
    <>
      Previous attempts:
      {attemptsMarkup()}
    </>
  );
}
